import React, { useState } from 'react'
import Layout from '../../components/layouts/layout.component'
import { MainQuotationForm } from '../../components/quotationMain/MainQuotationForm.component'
import { MainQuotationResult } from '../../components/quotationMain/MainQuotationResult.component'
import ResponsiveStatusModal from '../../components/common/ResponsiveStatusModal.component'
import { useMediaQuery, useTheme } from '@material-ui/core'
import ModalExitPrompt from '../../components/common/ModalExitPrompt.component'
import { showModalExitPrompt } from '../../context/modalExitPrompt/utils'
import { useAppContext } from '../../context/AppContext'
import setFormHasData from '../../context/modalExitPrompt/actions/setFormHasData'
import QuotationPropose from '../../components/quotationMain/QuotationPropose.component'
import SaleCarSuccess from '../../components/sell-your-car/SaleCarSuccess.component'
import Box from '@material-ui/core/Box'
import { SUBTITLE_MODAL_PROMPT, TITLE_MODAL_PROMPT } from '../../components/common/constant'

const PAGE_TITLE = 'Catálogo de Autos - Carmuv'
const META_DESCRIPTION =
  'Conocé todos los autos que se comercializan en Argentina. Encontrá en nuestro catálogo fichas tecnicas, fotos e información actualizada.'
const MESSAGE_ERROR = (
  <>
    Parece que esta cotización <br /> no la tenemos
  </>
)
const PRIMARY_BTN_ERROR = 'Volver a intentar'
const SECONDARY_BTN_ERROR = 'Volver al home'
const RESERVE_LEYEND_ERROR = (
  <>
    Podés cargar otro auto o <br /> intentarlo en otro momento.
  </>
)
const CAR_INITIAL_STATE = {
  brand: null,
  model: null,
  version: null,
  year: null,
  km: null,
}

export const QuotationPageContainer = ({ location, pageContext }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [formData, setFormData] = useState({
    car: {
      brand: null,
      model: null,
      version: null,
      year: null,
      km: null,
      isKmsCalculated: false,
    },
    client: {},
    clientError: {},
    isValidClient: false,
    checkAutoperitaje: true,
  })
  const [showErrorModal, setShowErrorModal] = useState(false)

  const [currentStep, setCurrentStep] = useState({
    order: 0,
    html: <MainQuotationForm />,
  })

  const {
    modalExitPrompt,
    modalExitPromptDispatch,
  } = useAppContext()

  const showModalExitFunction = (prevRoute, nextRoute) =>
    showModalExitPrompt(prevRoute, nextRoute, modalExitPrompt)

  const handleEmptyForm = (isEmpty) => {
    setFormHasData(modalExitPromptDispatch, !isEmpty)
  }
  const handleNextStep = () => {
    setCurrentStep((prevCurrentStep) => steps[prevCurrentStep.order + 1])
    window.scrollTo(0, 0)
  }

  const handleBackStep = () => {
    setCurrentStep((prevCurrentStep) => steps[prevCurrentStep.order - 1])
    window.scrollTo(0, 0)
  }
  const handleConfirmErrorModal = () => {
    setShowErrorModal(false)
    setFormData({
      car: CAR_INITIAL_STATE,
    })
  }
  const clearForm = () => {
    setFormData((formData) => ({
      ...formData,
      car: CAR_INITIAL_STATE,
    }))
  }

  const steps = [
    {
      order: 0,
      html: (
        <MainQuotationForm
          formData={formData}
          setFormData={setFormData}
          onNextStep={() => handleNextStep()}
          onEmptyForm={(isEmpty) => {
            handleEmptyForm(isEmpty)
          }}
        />
      ),
    },
    {
      order: 1,
      html: (
        <MainQuotationResult
          formData={formData}
          setFormData={setFormData}
          onNextStep={() => handleNextStep()}
          onBackStep={() => handleBackStep()}
          setShowErrorModal={setShowErrorModal}
          clearForm={clearForm}
        />
      ),
    },
    {
      order: 2,
      html: <QuotationPropose
        formData={formData}
        setFormData={setFormData}
        onNextStep={() => handleNextStep()}
        onBackStep={() => handleBackStep()}
        onEmptyForm={() => handleEmptyForm(true)}
      />,
    },
    {
      order: 3,
      html: <Box width="100%" display="flex" flexDirection="column" marginTop="5rem">
        <SaleCarSuccess />
      </Box>,
    }
  ]
  return (
    <Layout
      title={PAGE_TITLE}
      location={location}
      pageContext={pageContext}
      showSubHeader={currentStep.order === 0}
      description={META_DESCRIPTION}
    >
      {steps[currentStep.order].html}

      <ModalExitPrompt
        title={TITLE_MODAL_PROMPT}
        subtitle={SUBTITLE_MODAL_PROMPT}
        primaryActionText="Salir"
        secondaryActionText="Volver"
        originRoute={location.pathname}
        showModal={showModalExitFunction}
      />

      {showErrorModal && (
        <ResponsiveStatusModal
          isMobile={isMobile}
          open={true}
          errorMessage={MESSAGE_ERROR}
          errorLegend={RESERVE_LEYEND_ERROR}
          errorTextBtn={PRIMARY_BTN_ERROR}
          errorTextSecondBtn={SECONDARY_BTN_ERROR}
          onErrorBtnClick={() => handleConfirmErrorModal()}
          onClose={() => {
            setShowErrorModal(false)
          }}
        />
      )}
    </Layout>
  )
}
