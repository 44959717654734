import React from 'react'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { makeStyles, Box } from '@material-ui/core'
import Text from '../common/text.component'
import Link from '../common/Link.component'

const useStyles = makeStyles((theme) => ({
  goBack: {
    marginLeft: '4rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: '600',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '1rem',
    },
  },
}))

const GoBackSection = ({ onBackStep }) => {
  const classes = useStyles()
  return (
    <Link inverted onClick={onBackStep} className={classes.goBack}>
      <Box display="flex" alignItems="center">
        <ArrowBackIosIcon style={{ fontSize: 10 }} />
        <Text small>Volver</Text>
      </Box>
    </Link>
  )
}

export default GoBackSection
