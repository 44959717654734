import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import Text from '../common/CustomTypography.component'
import Select from '../common/forStorybook/Select.component'
import TextFieldSB from '../common/forStorybook/TextField.component';
import {
  GET_BRANDS,
  GET_MODELS,
  GET_MODEL_VERSIONS,
  GET_MODEL_YEARS,
} from '../../queries'
import { useQuery } from '@apollo/react-hooks'
import Button from '../common/button/Button.component.jsx'
import { calculateKms } from '../common/quotationHelper.js'

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '100%',
    maxWidth: '616px',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    margin: '2.5rem auto 3.5rem auto',
    padding: '0',
    '& .MuiFormControl-root':{
      marginTop: 0,
    },
    '& .MuiFormLabel-root':{
      marginLeft: '6px',
    },
    '& .MuiFormLabel-filled':{
      fontWeight: '500',
    },
    '& .MuiAutocomplete-input:first-child':{
      marginLeft: '6px',
    },
    [theme.breakpoints.down('md')]: {
      marginTop: '24px',
      padding: '0 1rem',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginBottom: '12px',
  },
  modelAndYear: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: '16px',
    },
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    [theme.breakpoints.down('md')]: {
      gap: '16px',
    },
  },
  selectField: {
    width: '100%',
  },
}))
const LABEL_KMS = 'Kilometraje'
const MESSAGE_ERROR_KM = 'Contanos cuántos kilómetros tiene.'
const MESSAGE_ERROR_BRAND = 'Contanos cuál es la marca.'
const MESSAGE_ERROR_MODEL = 'Contanos qué modelo es.'
const MESSAGE_ERROR_YEAR = 'Contanos de qué año es.'
const MESSAGE_ERROR_VERSION = 'Contanos cuál es la versión.'

export const MainQuotationForm = ({
  formData,
  setFormData,
  onNextStep,
  onEmptyForm,
}) => {
  const classes = useStyles()
  const [kilometraje, setKilometraje] = useState(formData.car.isKmsCalculated ? '' : formData.car.km)
  const [errors, setErrors] = useState({
    brand: false,
    model: false,
    year: false,
    version: false,
    kilometros: false,
  })

  useEffect(() => {
    if (!formData.car.km) {
      setKilometraje('')
    }
  }, [formData.car.km])

  const { data: brandData, loading: loadingBrands } = useQuery(GET_BRANDS, {
    variables: { all: true },
    fetchPolicy: 'network-only',
  })

  const { data: modelData, loading: loadingModels } = useQuery(GET_MODELS, {
    variables: {
      brandId: formData.car.brand?.value[0],
      published: null,
      requiredPrices: true,
    },
    skip: !formData.car.brand,
  })

  const { data: yearsData, loading: loadingYears } = useQuery(GET_MODEL_YEARS, {
    variables: {
      brandId: formData.car.brand?.value[0],
      modelId: formData.car.model?.value[0],
    },
    skip: !formData.car.model,
  })

  const { data: versionsData, loading: loadingVersions } = useQuery(
    GET_MODEL_VERSIONS,
    {
      variables: {
        brandId: formData.car.brand?.value[0],
        modelId: formData.car.model?.value[0],
        year: formData.car.year?.value,
      },
      skip: !formData.car.year,
    }
  )
  const { brands = [] } = brandData || {}
  const { models = [] } = modelData || {}
  const { years = [] } = yearsData || {}
  const { versions = [] } = versionsData || {}

  useEffect(() => {
    const isEmpty = !Boolean(formData.car.brand) && !Boolean(formData.car.km)
    onEmptyForm(isEmpty)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.car.brand, formData.car.km])


  const validationsForm = () => {
    const requiredFields = ['brand', 'model', 'year', 'version'];
    const missingField = requiredFields.find(field => !formData.car[field]);
    if (missingField) {
      setErrors((prevData) => ({ ...prevData, [missingField]: true }));
      return;
    }
    const isKmsCalculated = !kilometraje
    const km = calculateKms(kilometraje, formData.car.year.value, formData.car.version.tipoCotizador)

    setFormData(prevData => ({
      ...prevData,
      car: { ...prevData.car, km, isKmsCalculated },
    }));

    onNextStep();
  };

  return (
    <div className={classes.formContainer}>
      <div className={classes.title}>
        <Text variant="h2" customColor="gris07">
          Datos del auto
        </Text>
        <Text variant="body1" customColor="gris07">
          Completa el formulario para conocer el precio de tu auto en el mercado.
        </Text>
      </div>
      <div className={classes.fieldsContainer}>
        <div id='cotizador-datosDelAuto-field-marca'>
        <Select
          label={'Marca'}
          options={brands.map((b) => ({
            label: b.name,
            value: b.id,
          }))}
          loading={loadingBrands}
          selectedOption={formData.car.brand}
          setSelectedOption={(value) => {
            setErrors((prevData) => ({ ...prevData, brand: false }));
            setFormData((prevData) => ({
              ...prevData,
              car: { ...prevData.car, brand: value, model: null, year: null, version: null },
            }))
          }}
          getOptionLabel={(option) => option.label}
          disableClearable
          error={errors.brand}
          helperText={errors.brand && MESSAGE_ERROR_BRAND}
        />
        </div>
        <div className={classes.modelAndYear}>
          <div id='cotizador-datosDelAuto-field-modelo' className={classes.selectField}>
          <Select
            disabled={!formData.car.brand}
            label={'Modelo'}
            options={models.map((m) => ({
              label: m.name,
              value: m.id,
            }))}
            loading={loadingModels}
            selectedOption={formData.car.model}
            setSelectedOption={(value) => {
              setErrors((prevData) => ({ ...prevData, model: false }));
              setFormData((prevData) => ({
                ...prevData,
                car: { ...prevData.car, model: value, year: null, version: null },
              }))
            }}
            getOptionLabel={(option) => option.label}
            disableClearable
            error={errors.model}
            helperText={errors.model && MESSAGE_ERROR_MODEL}
          />
          </div>
          <div id='cotizador-datosDelAuto-field-año' className={classes.selectField}>
          <Select
            disabled={!formData.car.model}
            label={'Año'}
            options={years.map((y) => ({
              label: y.label,
              value: y.year,
            }))}
            loading={loadingYears}
            selectedOption={formData.car.year}
            setSelectedOption={(value) => {
              setErrors((prevData) => ({ ...prevData, year: false }));
              setFormData((prevData) => ({
                ...prevData,
                car: { ...prevData.car, year: value, version: null },
              }))
            }}
            getOptionLabel={(option) => option.label}
            disableClearable
            error={errors.year}
            helperText={errors.year && MESSAGE_ERROR_YEAR}
          />
          </div>
        </div>
        <div id='cotizador-datosDelAuto-field-version'>
        <Select
          disabled={!formData.car.year}
          label={'Version'}
          options={versions.map((v) => ({
            label: v.name,
            value: v.id,
            tipoCotizador: v.tipo_cotizador,
          }))}
          loading={loadingVersions}
          selectedOption={formData.car.version}
          setSelectedOption={(value) => {
            setErrors((prevData) => ({ ...prevData, version: false }));
            setFormData((prevData) => ({
              ...prevData,
              car: { ...prevData.car, version: value },
            }))
          }}
          getOptionLabel={(option) => option.label}
          disableClearable
          error={errors.version}
          helperText={errors.version && MESSAGE_ERROR_VERSION}
        />
        </div>
      <div id='cotizador-datosDelAuto-field-kilometros'>
      <TextFieldSB
        label={LABEL_KMS}
        value={kilometraje}
        variant='outlined'
        type='number'
        error={errors.kilometros}
        isOptional
        onChange={(evt) => {
          const newKm = evt.target.value
          const maxLengthKm = 10
          if (newKm && newKm.length > 1) {
            setErrors((prevData) => ({ ...prevData, kilometros: false }));
          }
          if (newKm.length < maxLengthKm) setKilometraje(newKm)
        }}
        helperText={errors.kilometros && MESSAGE_ERROR_KM}
      />
      </div>

      </div>
      <div className={classes.button}>
        <Button
          id='cotizador-datosDelAuto-btn-cotizar-background'
          type="primary"
          onClick={() => validationsForm()}
        >
          <span id='cotizador-datosDelAuto-btn-cotizar-txt' >Cotizar</span>
        </Button>
      </div>
    </div>
  )
}
