import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '../common/button/Button.component'
import { useQuery } from '@apollo/react-hooks'
import { GET_QUOTE_FORM } from '../../queries/getQuoteForm'

const useStyles = makeStyles(() => ({
  button: {
    maxWidth: '38.5rem',
    width: '100%',
    display: 'block',
    margin: '1rem auto 2.5rem auto',
  },
  buttonContainer: {
    padding: '0 1rem',
  },
}))

const ButtonQuotationSend = ({ onClick, formData }) => {
  const classes = useStyles()
  const { data } = useQuery(GET_QUOTE_FORM)

  const { isValidClient, checkAutoperitaje } = formData

  const onClickSend = async () => {
    if (!isValidClient) return
    if (checkAutoperitaje)
      window.open(data.getQuoteForm, '_blank')
    await onClick()
  }

  return (
    <div className={classes.buttonContainer}>
      <Button
        id='btn-enviar-cotizador-autos'
        primary
        onClick={onClickSend}
        classNameProps={classes.button}
      >
        <span>Enviar</span>
      </Button>
    </div>
  )
}

export default ButtonQuotationSend
