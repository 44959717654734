import React from 'react'
import { QuotationPageContainer } from '../containers/quotationMain/QuotationPage.container'
import { Router } from '@reach/router'

const QuotationPage = (props) => {
  return (
    <Router basepath="/cotizador-autos">
      <QuotationPageContainer {...props} path="/" />
    </Router>
  )
}

export default QuotationPage
