import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks'
import { makeStyles } from '@material-ui/core';
import { GET_QUOTATION } from '../../queries'
import LoadingModal from '../common/LoadingModal.component'
import { formaterNumber } from '../../utils/currency.utils';
import CustomTypography from '../common/CustomTypography.component';
import { NEW_PALETTE } from '../../assets/theme';
import Button from '../common/button.component';
import { calculateKms } from '../common/quotationHelper';
import GoBackSection from './GoBackSection';
import { QuotationLegalBox } from './QuotationLegalBox';

const defaultInterior = 5
const REFERENCE_PRICE = 'reference'
const ESTIMATED_PRICE = 'estimated'

const TITLE = 'Resultado de cotización';
const SUBTITLE = 'Este es el valor que encontramos para tu auto.';
const MARKET_VALUE = 'Valor del mercado';
const INTEREST_IN_SALE = '¿Te interesa venderlo?';
const INTEREST_NOTE = 'Con Carmuv es fácil y rápido. Ahorra tiempo y vendé sin complicaciones.';
const WANT_TO_SALE = 'Quiero venderlo';

const useStyle = makeStyles( (theme) => ({
  pageContainer: {
    marginTop: '5rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  container: {
    width: '45%',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '1rem',
    },
  },
  headersSection: {
    marginTop: '1rem',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  marketValueCard: {
    width: '100%',
    marginTop: '1rem',
    padding: '1.5rem',
    borderRadius: '1rem',
    backgroundColor: '#F7F2FF',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2rem 1rem',
    },
  },
  marketValueCardMMV: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
  },
  marketValueCardYearKm: {
    display: 'flex',
    gap: '0.25rem',
    color: NEW_PALETTE.neutral.gray06,
  },
  marketValueCardYearKmBull: {
    fontWeight: '900',
  },
  marketValueCardPrice: {
    fontSize: '1.8125rem',
    fontWeight: '600',
    color: NEW_PALETTE.neutral.gray07,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
  },
  interestSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1rem',
    border: '1px solid',
    borderRadius: '1rem',
    padding: '1.5rem 1.5rem 0.5rem 1.5rem',
    marginTop: '2rem',
    color: NEW_PALETTE.neutral.gray03,
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 1rem 1rem 1rem',
    },
  },
  wantToSale: {
    border: 0,
    borderRadius: '4px',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
    padding: 0,
  },
}));

const HeadersSection = ({ classes }) => {
  return <div className={classes.headersSection}>
    <CustomTypography variant='h2'>{TITLE}</CustomTypography>
    <CustomTypography variant='body1' customColor='gris07'>{SUBTITLE}</CustomTypography>
  </div>
}

const MarketValueCard = ({ classes, car, price }) => {
  const {
    brand: { label: brand },
    model: { label: model },
    version: { label: version },
    year: { label: year },
    isKmsCalculated,
    km,
  } = car

  // TODO: maybe improve css and html: year, divider, km & price
  return <div className={classes.marketValueCard}>
    <CustomTypography variant='subtitle1'>{MARKET_VALUE}</CustomTypography>
    <div className={classes.marketValueCardMMV}>
      <CustomTypography variant='subtitle2'>{brand} {model}</CustomTypography>
      <CustomTypography variant='body1' customColor='gris07'>{version}</CustomTypography>
      <div className={classes.marketValueCardYearKm}>
        <span>{year}</span>
        { !isKmsCalculated && <>
          <span className={classes.marketValueCardYearKmBull}>&nbsp;&#183;&nbsp;</span>{' '}
          <span>{formaterNumber(km)} Km</span> </> }
      </div>
    </div>
    <span className={classes.marketValueCardPrice}>$ {formaterNumber(price)}</span>
  </div>
}

const InterestSection = ({ classes, onNextStep }) => {
  const handleOnClick = () => {
    onNextStep()
  }

  return <div className={classes.interestSection}>
    <CustomTypography variant='subtitle1'>{INTEREST_IN_SALE}</CustomTypography>
    <CustomTypography variant='body1'>{INTEREST_NOTE}</CustomTypography>
    <Button
      id='cotizador-resultado-btn-quieroVenderlo'
      data-test-id="cotizador-resultado-btn-quieroVenderlo"
      primaryInverted
      className={classes.wantToSale}
      onClick={handleOnClick}>
      {WANT_TO_SALE}
    </Button>
  </div>
}

export const MainQuotationResult = ({
  formData,
  setShowErrorModal,
  onBackStep,
  onNextStep,
  setFormData,
  clearForm,
}) => {
  const { car, quote } = formData;
  const classes = useStyle();

  const {
    loading: loadingGetQuotation,
    error: errorGetQuotation,
    data: dataGetQuotation,
  } = useQuery(GET_QUOTATION, {
    variables: {
      info: {
        interior: defaultInterior,
        kms: parseInt(calculateKms(car.km, car.year.value, car.version.tipoCotizador)),
        vehicle: {
          brand: car.brand.value[0],
          model: car.model.value[0],
          version: car.version.value,
          year: car.year.value,
        },
      },
    },
    fetchPolicy: 'network-only',
    onError: () => {
      clearForm()
    }
  })

  useEffect(() => {
    if (!dataGetQuotation?.carQuote?.quote?.prices) return;
    const calcPrice = (price) => dataGetQuotation.carQuote?.quote?.prices?.find(q => q?.type === price)?.amount;
    setFormData((prevData) => ({
      ...prevData,
      car: {
        ...prevData.car,
      },
      quote: {
        prices: {
          reference: calcPrice(REFERENCE_PRICE),
          estimated: calcPrice(ESTIMATED_PRICE),
        },
        date: dataGetQuotation.carQuote.date,
      }
    }));
  }, [dataGetQuotation, setFormData])

  if (loadingGetQuotation) return <LoadingModal />

  if (errorGetQuotation || dataGetQuotation == null) {
    setShowErrorModal(true)
    onBackStep()
    return <LoadingModal />
  }

  const referencePrice = quote?.prices?.reference

  return <div className={classes.pageContainer}>
    <GoBackSection onBackStep={onBackStep} />
    <div className={classes.container}>
      <HeadersSection classes={classes} />
      <MarketValueCard classes={classes} car={car} price={referencePrice} />
      <InterestSection classes={classes} onNextStep={onNextStep}/>
      <QuotationLegalBox />
    </div>
  </div>
};
