import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import { useMutation } from '@apollo/react-hooks';

import ThisIsOurProposalForYou from './ThisIsOurProposalForYou'
import ButtonQuotationSend from './ButtonQuotationSend'
import ResponsiveStatusModal from '../common/ResponsiveStatusModal.component'
import { SUCCESS_MODAL_TEXT } from '../../containers/catalog-detail/constant.js'
import { QuotationClientForm } from './clientForm.jsx'
import { QuotationExpertiseForm } from './QuotationExpertiseForm.jsx'
import GoBackSection from './GoBackSection.jsx'
import { QuotationLegalBox } from './QuotationLegalBox.jsx'
import { SEND_SALE_CAR_FORM_TO_ASOFIX } from '../../mutations/sendSaleCarFormToAsofix.js'

const useStyle = makeStyles( (theme) => ({
  legalsContainer: {
    width: '616px',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const QuotationPropose = ({ formData = {}, setFormData, onBackStep, onNextStep, onEmptyForm }) => {
  const classes = useStyle()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const [openModalResponse, setOpenModalResponse] = useState(false)
  const [success, setSuccess] = useState(false)

  const setCheckAutoperitaje = (value) => {
    setFormData(prev => ({
      ...prev,
      checkAutoperitaje: value,
    }))
  }

  const [sendSaleCarForm, { loading }] = useMutation(SEND_SALE_CAR_FORM_TO_ASOFIX, {
    onCompleted: () => {
      setSuccess(true)
      onEmptyForm()
      onNextStep()
    },
    onError: () => {
      setSuccess(false)
    },
  })

  const onClick = async () => {
    const { car, client, quote } = formData

    const contactForm = {
      car: {
        brand: { id: car.brand.value[0], name: car.brand.label },
        model: { id: car.model.value[0], name: car.model.label },
        version: { id: car.version.value, name: car.version.label },
        year: parseInt(car.year.value),
        mileage: parseInt(car.km),
        isKmsCalculated: car.isKmsCalculated,
        condition: 0,
        tipo_cotizador: 'Autos',
      },
      client: {
        name: client.firstName,
        lastName: client.lastName,
        email: client.email,
        phone: client.phone,
        appraisalLocation: formData?.checkAutoperitaje ? 'Autoperitaje' : 'Peritaje presencial',
      },
      quote
    }
    sendSaleCarForm({ variables: { contactForm } })
    setOpenModalResponse(true)
  }

  const estimatedPrice = formData.quote?.prices?.estimated

  return (
    <Box width="100%" display="flex" flexDirection="column" marginTop="5rem">
      <GoBackSection onBackStep={onBackStep} />
      <ThisIsOurProposalForYou formData={formData} price={estimatedPrice} />
      <QuotationClientForm formData={formData} setFormData={setFormData} />
      <QuotationExpertiseForm
        checkAutoperitaje={formData?.checkAutoperitaje}
        setCheckAutoperitaje={setCheckAutoperitaje}
      />
      <ButtonQuotationSend onClick={onClick} formData={formData} setFormData={setFormData} />
      <div className={classes.legalsContainer}>
        <QuotationLegalBox mobilePadding="1rem" />
      </div>
      <ResponsiveStatusModal
        success={success}
        loading={loading}
        isMobile={isMobile}
        open={openModalResponse}
        loadingMessage={SUCCESS_MODAL_TEXT.loadingMessage}
        loadingLegend={SUCCESS_MODAL_TEXT.loadingLegend}
        errorMessage={SUCCESS_MODAL_TEXT.errorMessage}
        errorLegend={SUCCESS_MODAL_TEXT.errorLegend}
        errorTextBtn={SUCCESS_MODAL_TEXT.errorTextBtn}
        secondarySuccessTextBtn={null}
        onSecondarySuccessClick={() => {}}
        onErrorBtnClick={() => {
          setOpenModalResponse(false)
        }}
        onClose={() => setOpenModalResponse(false)}
      />
    </Box>
  )
}

export default QuotationPropose
